
.minted {
  float: right;
  color: #fff;
  margin: 10px;
  padding: 10px;
}

.textbox {
  background: transparent !important;
  border-bottom: 1px solid #fff !important;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-radius: 0px;
  margin-right: 10px;
}

body {
  font-family: "Bubblegum Sans", sans-serif;
  color: #000000;
  background-color: #fdd70d;
}

a {
  color: #2487ce;
}
a:hover {
  color: #469fdf;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Bubblegum Sans", sans-serif;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #001613;
  z-index: 997;
  padding: 15px 0;
}

#header .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 300;
  letter-spacing: 0.5px;
  font-family: "Bubblegum Sans", sans-serif;
}
#header .logo a{color: #fff;}

#header .logo img {
  max-height: 50px;
}

@media (max-width: 992px) {
  #header .logo {
    font-size: 28px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul {
  display: flex;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  padding: 14px 0 14px 28px;
  line-height: 3;
}

.nav-menu a {
  display: block;
  position: relative;
  color: #000000;
  transition: 0.3s;
  font-size: 14px;
  font-family: "Bubblegum Sans", sans-serif;
}

.nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
  color: #f95f63;
  text-decoration: underline;
}
/* SearchBox */
.has-search{width: 40%;}
.has-search .form-control {
    padding-left: 2.375rem;
	background-color: #003930;
	border-radius: 20px;
	border-color: #003930;
	 color: #fff;
	
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #fff;
	
	
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff !important;
  font-size: 12px !important
}
.form-group {
    margin-bottom: 0rem;
}
/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 18px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #fff;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #124265;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
}

.mobile-nav a:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
  color: #2487ce;
  text-decoration: none;
}

.mobile-nav .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down > a:after {
  content: "\eaa1";
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(10, 38, 58, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero{position: relative;}

#hero h1 {
  margin: 0;
  font-size: 36px;
font-weight: 500;
  color: #000000;
   border-bottom: 1px solid #fff;
   line-height: 65px;
  font-family: "Bangers", sans-serif;
  margin-top: 0px;
}

#hero h2 {
  color: ffffff;
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 0px;
 
}

.bottom-left {
  position: absolute;
  bottom: 0px;
  background: #fdd70d;
  padding: 25px;
  
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-height: 800px) {
  #hero {
    height: auto;
  }
}

@media (max-width: 992px) {
  #hero {
    height: auto;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 80px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #fdad03;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #124265;
}

.section-title p {
  margin-bottom: 0;
  font-size: 14px;
  color: #919191;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content p:last-child {
  margin-bottom: 0;
}

/* blog */

.bg-offwhite{background: #feffbd;border: 2px solid #eee;}
.bg-lightgreen{background: #b5ffd6;}
#blog1{background: #fff;color: #000;}
#blog1 img{width: 200px;}
#blog1 h4{color: #000000;}
.btn-blue{background: #025;color: #fff;}
.btn-green{background: #00c6a8;color: #fff;}
/*--------------------------------------------------------------
# projects
--------------------------------------------------------------*/
.projects {
  padding: 60px 0;
}
.turquoise{color: #02c5a7 !important;}
.projects .project-box {
  width: 100%;
  text-align: center;
  background: #001613;
  border-radius: 14px;
  padding: 20px;
  border: 2px solid #fff;
}
.projects .project-box h5{font-weight: 800;margin-bottom: 0px;}
.line{border-top: 1px solid #fff;}
.btn-join{font-size: 12px;text-transform: uppercase;color: #000;background: #fe6464;border: 0px;font-weight: 500;}
.btn-join:hover{color: #fe6464;background: #fff ;border: 0px;}
/*--------------------------------------------------------------
# Join
--------------------------------------------------------------*/
.join{background: #b0ff54;color: #000;padding: 20px;border-radius: 10px;}
.join h2{color: #000;}
.btn-2{font-size: 12px;color: #000;background: #000;border: 0px;font-weight: 500;}
.btn-2:hover{background: #fff ;border: 0px;color: #000;}


/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}


.team .member .member-info {
  padding: 10px 15px;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: 000000;
}

.productex{background: #001613;color: #000000;border-radius: 4px;padding-bottom: 20px;padding-top: 20px;}
.productex h5{font-weight: 300;padding: 10px 20px 0px 20px;}
.btn-3{font-size: 12px;color: #000000;background: #00c6a7;border: 0px;font-weight: 500;padding: 10px;}
.btn-3:hover{background: #fff ;border: 0px;color: #00c6a7;}
.textbox{background: transparent !important;border-bottom: 1px solid 000000;border-top: 0px;border-right: 0px;border-left: 0px;border-radius: 0px;margin-right: 10px;}

/* teambanner */
.teambannerbg{background: #fdad03;}
.font800{font-weight: 800;}
.Portfolio {
    position: relative;
    margin: 5px;
    border: 2px solid black;
    float: left;
    width: 180px;
    transition-duration: 0.4s;
    border-radius: 5px;
	animation: winanim 0.5s ;
-webkit-backface-visibility:visible;
    backface-visibility:visible;
    box-shadow:0 3px 5px -1px rgba(0,0,0,.2),0 5px 8px 0 rgba(0,0,0,.14),0 1px 14px 0 rgba(0,0,0,.12)
}

.Portfolio:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19);
}

.Portfolio img {
    width: 100%;
    height: auto;
}

.desc {
    padding: 5px;
    text-align: center;
    font-size: 90%;
    background:black;
    color:hotpink
}

.Portfolio .nav {
    padding:20px;
   
}

.Portfolio .nav li a { 
    margin:5px;
    padding: 15px 50px; 
    font-size:16px;
    background: transparent !important;
}
.Portfolio .nav a:hover { 
    background:#333; 
}
.Portfolio .nav .active { 
    background-color:000000 !important;
    color:#000 !important;
	
}
.nav-pills .nav-link{padding: 10px 30px !important;
    border-radius: 40px !important;
border: 2px solid 000000;margin-right: 20px;margin-top: 20px;margin-bottom: 5px;color: #fff;}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link, .nav-pills .nav-link.hover {
    color: #000; 
    background-color: 000000 !important;
	
}
@keyframes winanim {
    0%{opacity:0;transform:scale3d(.3,.3,.3)}
    50%{opacity:1}
    
}
.exp{border: 0px !important;font-size: 22px;}
.textper{color: #f95f63;font-weight: bold;font-size: 110%;}
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: 000000;
  font-size: 14px;
  box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  color: #fff;
  line-height: 1;
  padding: 22px 0;
  margin-right: 4px;
  border-radius: 5px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: #3194db;
  color: #fff;
  text-decoration: none;
}
